<template>
  <div class="contain">
    <!-- 发病患病率-总部-->
    <div class="form-header-btn">
      <div class="header-title">督导项目</div>
      <div>
        <el-button type="primary" class="sheet-btn" v-if="isAddProject" @click="addProject" icon="el-icon-plus">新增项目</el-button>
      </div>
    </div>

    <el-form :model="form" onsubmit="return false" :inline="true" class="search-form">
      <el-form-item>
        <el-input v-model="projectName" placeholder="督导项目查询" />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="searchFun">
          查询
        </el-button>
      </el-form-item>
      <el-form-item style="float: right;">
        <el-radio-group v-model="expensionType" @change="handleExpension">
          <el-radio :label="'1'">展开一级</el-radio>
          <el-radio :label="'2'">展开二级</el-radio>
          <el-radio :label="'3'">展开三级</el-radio>
          <el-radio :label="'all'">展开全部</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table :row-class-name="tableRowClassName" :data="tableData" :header-cell-style="{ background: '#F1F4F9', color: '#5E6D9B', fontWeight: '400', 'text-align':'center' }" v-loading="tableLoading" :expand-row-keys="expandRow" ref="tableData" row-key="id" :tree-props="{ children: 'supProList', hasChildren: 'hasChildren' }">
      <el-table-column prop="projectName" label="督导项目" min-width="320">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="light" popper-class="customPopper" :content="scope.row.projectName" placement="top" v-if="scope.row.projectName">
            <div class="description-div project bold" v-if="scope.row.level != '4'">{{ scope.row.projectName }}</div>
            <div class="description-div project" v-else>{{ scope.row.projectName }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="score" label="总分" align="center" min-width="100">
        <template slot-scope="scope">
          <span :class="{ error: scope.row.type == '2' }" v-if="scope.row.level == '4'">{{ scope.row.score }}</span>
          <span v-else>{{ scope.row.score }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="类型" align="center" min-width="100">
        <template slot-scope="scope">
          <span :class="{ error: scope.row.type == '2' }" v-if="scope.row.level == '4'">{{ scope.row.type == '2' ? '扣分题' : scope.row.type == '1' ? '得分题' : '' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间" align="center" min-width="120"></el-table-column>
      <el-table-column prop="sort" label="排序" align="center" min-width="150">
        <template slot-scope="scope">
          <div v-if="scope.row.level != '4'">
            <el-button type="text" title="上移" icon="el-icon-top" size="mini" v-if="scope.row.sortIndex != 0 && isUpdate" circle @click="changeSort('1', scope.row)"></el-button>
            <el-button type="text" title="移到顶部" v-if="scope.row.sortIndex != 0 && isUpdate" icon="el-icon-upload2" size="mini" circle @click="changeSort('2', scope.row)"></el-button>
            <el-button type="text" title="下移" icon="el-icon-bottom" size="mini" v-if="scope.row.isLast && isUpdate" circle @click="changeSort('3', scope.row)"></el-button>
            <el-button type="text" title="移到底部" icon="el-icon-download" v-if="scope.row.isLast && isUpdate" size="mini" circle @click="changeSort('4', scope.row)"></el-button>
          </div>
          <span v-else>{{ scope.row.sort || 0 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="isEnable" label="状态" align="center" min-width="120">
        <template slot-scope="scope">
          <el-switch v-if="scope.row.level == '4'" v-model="scope.row.isEnableStatus" :disabled="!isUpdate" active-color="#003685" inactive-color="#C5C3D8" :active-text="scope.row.isEnable === '1' ? '启用' : '禁用'" :inactive-text="''" @change="val => handleProjectEnableChange(val,scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="250">
        <template slot-scope="scope">
          <el-button class="table-handle" @click="handleView(scope.row)" type="text" v-if="scope.row.level == '4' && isView">查看</el-button>
          <el-button class="table-handle" @click="handleAddLevel(scope.row,'edit')" type="text" v-if="isUpdate">编辑</el-button>
          <el-popconfirm
            confirm-button-text='确定'
            cancel-button-text='取消'
            :icon="scope.row.level == '4' || (scope.row.level != '4' && !scope.row.supProList) ? 'el-icon-warning' : 'el-icon-error'"
            :icon-color="scope.row.level == '4' || (scope.row.level != '4' && !scope.row.supProList) ? '#E6BD89' : '#C6000B'"
            :title="scope.row.level == '4' || (scope.row.level != '4' && !scope.row.supProList) ? '你确定要删除此'+(scope.row.level != '4' ? '层级' : '项目')+'吗？' : '删除失败！有下级'+(scope.row.level != '4' ? '层级' : '项目')+'时不可删除~'"
            @confirm="handleDel(scope.row)"
            v-if="isDelete"
          >
            <el-button slot="reference" type="text" class="table-handle" v-if="isDelete">删除</el-button>
          </el-popconfirm>
          <el-button class="table-handle" @click="handleAddLevel(scope.row,'nextLevel')" type="text" v-if="scope.row.level != '4' && isAddChildLevel">新增下级</el-button>
          <el-button class="table-handle" @click="handleAddLevel(scope.row,'sameLevel')" v-if="isAddSameLevel" type="text">新增同级</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="quick-add-project" v-if="!tableLoading && tableData.length == 0 && isAddSameLevel" @click="handleAddLevel(null,'first')">
      <img :src="iconAdd" alt="">
      <span>快速创建</span>
    </div>
    <el-dialog class="project-level-dialog" :title="projectLevelTitle" :visible.sync="visible" :append-to-body="true" :destroy-on-close="true" @before-close="handleClose" @close="handleClose" width="40%">
      <el-form ref="projectLevelForm" :model="projectLevelForm" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item prop="projectName">
              <el-input v-model.trim="projectLevelForm.projectName" placeholder="请输入名称" :maxlength="20" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :loading="buttonloading" @click="handleClose">取 消</el-button>
        <el-button :loading="buttonloading" type="primary" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { getPermissionButton, isHasPermission, } from "@/utils/getUrlPermissionButtons";
import iconAdd from "@/assets/img/icon_add.png"; //添加icon
export default {
  name: 'superviseProject',
  data() {
    return {
      tableLoading: false, // 表格loading
      form: {
        projectName: ''
      }, //表单
      projectName: '',//搜索名称
      tableData: [], //表格数据
      attendanceDetailsList: [], //月天数
      expandRow: [],
      expandRowItem: [],
      expensionType: 'all', //展示全部
      iconAdd, //添加icon

      projectLevelTitle: '新增', //新增层级title
      visible: false, //弹窗标识
      projectLevelForm: {
        projectName: ''
      },//新增层级表单
      rules: {
        projectName: [
          { required: true, message: "请输入名称", trigger: "blur" },
        ],
      },
      buttonloading: false,//按钮loading

      levelType: '', //新增/编辑类型
      levelInfo: {}, //新增层级信息

      permissionButtonList: [], // 权限按钮list
      isAddProject: false,//新增项目
      isAddSameLevel: false,//新增同级
      isAddChildLevel: false,//新增下级
      isUpdate: false,//编辑
      isDelete: false,//删除
      isView: false,//查看
    };
  },
  computed: {
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isAddProject = val.indexOf("z_supervise:addProject") != -1;
          this.isAddSameLevel = val.indexOf("z_supervise:addtj") != -1;
          this.isAddChildLevel = val.indexOf("z_supervise:addxj") != -1;
          this.isUpdate = val.indexOf("z_supervise:edit") != -1;
          this.isDelete = val.indexOf("z_supervise:del") != -1;
          this.isView = val.indexOf("z_supervise:view") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created(){
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
  },
  activated(){
    this.init(null,'first');
  },
  mounted() {
    this.tableLoading = true;
    // this.init(null,'first');
  },

  methods: {
    getPermissionButton,
    isHasPermission,
    /** 初始化 */
    init(data, type) {
      let param = { showLevel: '1' }
      this.$api.getProjectTree(param).then((res) => {
        if(res.data.code == 0){
          console.log(res.data.data)
          let list = res.data.data;
          let expandRow = []
          let expandRowItem = []
          list.map((item,index) => {
            // item.level = '1'
            item.sortIndex = index
            item.firstIndex = index
            item.sendIndex = null
            item.thirdIndex = null
            item.fourIndex = null
            expandRow.push(item.id);
            expandRowItem.push(item)
            if(index == list.length - 1){
              item.isLast = 0
            } else {
              item.isLast = 1
            }
            if(item.supProList && item.supProList.length > 0){
              let itemTotal = 0
              item.supProList.map((info,i) => {
                // info.level = '2'
                info.sortIndex = i
                info.firstIndex = index
                info.sendIndex = i
                info.thirdIndex = null
                info.fourIndex = null
                expandRow.push(info.id);
                expandRowItem.push(info)
                if(i == item.supProList.length - 1){
                  info.isLast = 0
                } else {
                  info.isLast = 1
                }
                if(info.supProList && info.supProList.length > 0){
                  let infoTotal = null
                  info.supProList.map((thirdInfo,j) => {
                    // thirdInfo.level = '3'
                    thirdInfo.sortIndex = j
                    thirdInfo.firstIndex = index
                    thirdInfo.sendIndex = i
                    thirdInfo.thirdIndex = j
                    thirdInfo.fourIndex = null
                    expandRow.push(thirdInfo.id);
                    expandRowItem.push(thirdInfo)
                    if(j == info.supProList.length - 1){
                      thirdInfo.isLast = 0
                    } else {
                      thirdInfo.isLast = 1
                    }
                    if(thirdInfo.supProList && thirdInfo.supProList.length > 0){
                      let thirdInfoTotal = null
                      thirdInfo.supProList.map((fourInfo,fourIndex) => {
                        // fourInfo.level = '4'
                        delete fourInfo.supProList
                        fourInfo.sortIndex = fourIndex
                        fourInfo.firstIndex = index
                        fourInfo.sendIndex = i
                        fourInfo.thirdIndex = j
                        fourInfo.fourIndex = fourIndex
                        fourInfo.isEnableStatus = fourInfo.isEnable === '1' ? true : false
                        expandRow.push(fourInfo.id);
                        expandRowItem.push(fourInfo)
                        if(fourIndex == thirdInfo.supProList.length - 1){
                          fourInfo.isLast = 0
                        } else {
                          fourInfo.isLast = 1
                        }
                        thirdInfoTotal = thirdInfoTotal === null ? (fourInfo.type == '1' ? Number(fourInfo.score)+0 : 0) : (fourInfo.type == '1' ? thirdInfoTotal+Number(fourInfo.score) : thirdInfoTotal+0)
                      })
                      thirdInfo.score = thirdInfoTotal
                      infoTotal = thirdInfoTotal !== null ? (infoTotal === null ? thirdInfoTotal+0 : thirdInfoTotal+infoTotal) : null
                    } else {
                      thirdInfo.score = ''
                      delete thirdInfo.supProList
                    }
                  })
                  info.score = infoTotal !== null ? infoTotal : ''
                  itemTotal = infoTotal !== null ? (itemTotal === null ? itemTotal+0 : infoTotal+itemTotal) : null
                } else {
                  info.score = ''
                  delete info.supProList
                }
              })
              item.score = itemTotal !== null ? itemTotal : ''
            } else {
              item.score = ''
              delete item.supProList
            }
          })

          this.tableData = list
          this.tableLoading = false
          this.$nextTick(() => {
            if(this.expensionType == 'all'){
              this.expandRow = JSON.parse(JSON.stringify(expandRow))
              this.expandRowItem = JSON.parse(JSON.stringify(expandRowItem))
              this.resetTabDate(expandRowItem, 'all')
            } else {
              this.handleExpension(this.expensionType)
            }
          })
        } else {
          this.tableLoading = false
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
        this.tableLoading = false
      })
    },

    //改变顺序
    changeSort(type,row){
      let sortList = []
      let list = JSON.parse(JSON.stringify(this.tableData))
      switch(type){
        case '1':
          if(row.level == '1'){
            list[row.sortIndex] = list.splice(row.sortIndex - 1,1,list[row.sortIndex])[0]
          } else if(row.level == '2'){
            list[row.firstIndex].supProList[row.sortIndex] = list[row.firstIndex].supProList.splice(row.sortIndex - 1,1,list[row.firstIndex].supProList[row.sortIndex])[0]
          } else if(row.level == '3'){
            list[row.firstIndex].supProList[row.sendIndex].supProList[row.sortIndex] = list[row.firstIndex].supProList[row.sendIndex].supProList.splice(row.sortIndex - 1,1,list[row.firstIndex].supProList[row.sendIndex].supProList[row.sortIndex])[0]
          }
          break;
        case '2': 
          if(row.level == '1'){
            list[row.sortIndex] = list.splice(0,1,list[row.sortIndex])[0]
          } else if(row.level == '2'){
            list[row.firstIndex].supProList[row.sortIndex] = list[row.firstIndex].supProList.splice(0,1,list[row.firstIndex].supProList[row.sortIndex])[0]
          } else if(row.level == '3'){
            list[row.firstIndex].supProList[row.sendIndex].supProList[row.sortIndex] = list[row.firstIndex].supProList[row.sendIndex].supProList.splice(0,1,list[row.firstIndex].supProList[row.sendIndex].supProList[row.sortIndex])[0]
          }
          break;
        case '3': 
          if(row.level == '1'){
            list[row.sortIndex] = list.splice(row.sortIndex + 1,1,list[row.sortIndex])[0]
          } else if(row.level == '2'){
            list[row.firstIndex].supProList[row.sortIndex] = list[row.firstIndex].supProList.splice(row.sortIndex + 1,1,list[row.firstIndex].supProList[row.sortIndex])[0]
          } else if(row.level == '3'){
            list[row.firstIndex].supProList[row.sendIndex].supProList[row.sortIndex] = list[row.firstIndex].supProList[row.sendIndex].supProList.splice(row.sortIndex + 1,1,list[row.firstIndex].supProList[row.sendIndex].supProList[row.sortIndex])[0]
          }
          break;
        case '4': 
          if(row.level == '1'){
            list[row.sortIndex] = list.splice(list.length - 1,1,list[row.sortIndex])[0]
          } else if(row.level == '2'){
            list[row.firstIndex].supProList[row.sortIndex] = list[row.firstIndex].supProList.splice(list[row.firstIndex].supProList.length - 1,1,list[row.firstIndex].supProList[row.sortIndex])[0]
          } else if(row.level == '3'){
            list[row.firstIndex].supProList[row.sendIndex].supProList[row.sortIndex] = list[row.firstIndex].supProList[row.sendIndex].supProList.splice(list[row.firstIndex].supProList[row.sendIndex].supProList.length - 1,1,list[row.firstIndex].supProList[row.sendIndex].supProList[row.sortIndex])[0]
          }
          break;
      }
      if(row.level == '1'){
        list.map((item,index) => {
          sortList.push({
            sort:index,
            id:item.id
          })
        });
      } else if(row.level == '2'){
        list[row.firstIndex].supProList.map((item,index) => {
          sortList.push({
            sort:index,
            id:item.id
          })
        });
      } else if(row.level == '3'){
        list[row.firstIndex].supProList[row.sendIndex].supProList.map((item,index) => {
          sortList.push({
            sort:index,
            id:item.id
          })
        });
      }
      this.$api.projectUpdateSort({ sortList:sortList }).then((res) => {
        if(res.data.code == 0){
          this.tableLoading = true
          this.init()
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
      })
    },

    //改变状态
    handleProjectEnableChange(val,row){
      let list = JSON.parse(JSON.stringify(this.tableData))
      let param = {
        id: row.id,
        isEnable: val ? '1' : '0'
      }
      this.$api.projectUpdateStatus(param).then((res) => {
        if(res.data.code == 0){
          // list[row.firstIndex].supProList[row.sendIndex].supProList[row.thirdIndex].supProList[row.sortIndex].isEnable = val ? '1' : '0'
          // list[row.firstIndex].supProList[row.sendIndex].supProList[row.thirdIndex].supProList[row.sortIndex].isEnableStatus = val ? true : false
          // this.tableData = list
          this.tableLoading = true
          this.init()
        } else {
          this.$message.error(res.data.msg);
          list[row.firstIndex].supProList[row.sendIndex].supProList[row.thirdIndex].supProList[row.sortIndex].isEnable = val ? '0' : '1'
          list[row.firstIndex].supProList[row.sendIndex].supProList[row.thirdIndex].supProList[row.sortIndex].isEnableStatus = val ? false : true
          this.tableData = list
        }
      }).catch(() => {
        list[row.firstIndex].supProList[row.sendIndex].supProList[row.thirdIndex].supProList[row.sortIndex].isEnable = val ? '0' : '1'
        list[row.firstIndex].supProList[row.sendIndex].supProList[row.thirdIndex].supProList[row.sortIndex].isEnableStatus = val ? false : true
        this.tableData = list
      })
    },

    //删除
    handleDel(row){
      console.log(row)
      if(row.level === '4' || (row.level !== '4' && !row.supProList)){
        console.log(row)
        this.$api.removeProject(row.id).then((res) => {
          if(res.data.code == 0){
            this.$message({ type: "success", message: "删除成功!", duration: 2000, onClose: () => {
              this.tableLoading = true
              this.init()
            }, });
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(() => {
        })
      }
    },

    //搜索获取高亮行
    tableRowClassName({row, rowIndex}) {
      let returnFlag = false
      if(this.form.projectName !== ''){
        let list = this.form.projectName
        let nameList = row.projectName
        if(nameList.indexOf(list) != -1){
          returnFlag = true
        }
        // nameList.map((item) => {
        //   let index = list.findIndex((info) => { return info == item })
        //   if(index != -1){
        //     returnFlag = true
        //   }
        // })
        if (returnFlag) {
          return 'search-row'; //light-row
        }
      }
      return '';
    },

    //查看
    handleView(row){
      this.$router.push({ path: "/superviseProjectDetail", query: { id: row.id, type: 'view', jumpType: 'headquarters' } });
    },

    //新增（同级或者下级）
    handleAddLevel(row,type){
      if(row && row.level === '4' && type == 'edit'){
        this.$router.push({ path: "/superviseProjectDetail", query: { id: row.id, type: 'edit', jumpType: 'headquarters' } });
        return
      }
      if(row && row.level === '3' && type == 'nextLevel'){
        let checkNode = [row.id]
        let defaultOpenNode = [this.tableData[row.firstIndex].id]
        sessionStorage.setItem('nodeInfo',JSON.stringify({ checkNode: checkNode, defaultOpenNode: defaultOpenNode }))
        this.$router.push({ path: "/superviseProjectDetail", query: { type: 'add', jumpType: 'headquarters' } });
        return
      }
      if(row && row.level === '4' && type == 'sameLevel'){
        let checkNode = [row.parentId]
        let defaultOpenNode = [this.tableData[row.firstIndex].id]
        sessionStorage.setItem('nodeInfo',JSON.stringify({ checkNode: checkNode, defaultOpenNode: defaultOpenNode }))
        this.$router.push({ path: "/superviseProjectDetail", query: { type: 'add', jumpType: 'headquarters' } });
        return
      }
      if(type != 'first'){
        this.levelInfo = JSON.parse(JSON.stringify(row))
      }
      this.levelType = type
      this.visible = true
      this.$nextTick(() => {
        if(type == 'edit'){
          this.projectLevelTitle = '编辑'
          this.projectLevelForm.projectName = row.projectName
        }
      })
    },

    //新增项目
    addProject(){
      this.$router.push({ path: "/superviseProjectDetail", query: { type: 'add', jumpType: 'headquarters' } });
    },

    //新增层级弹窗关闭方法
    handleClose(){
      this.visible = false
      this.buttonloading = false
      this.$nextTick(() => {
        this.projectLevelTitle = '新增'
        this.projectLevelForm = {
          projectName: ""
        }
        this.levelType = ''
        this.levelInfo = {}
        this.$refs.projectLevelForm.clearValidate()
      })
    },

    //新增层级弹窗提交方法
    handleSubmit(){
      this.$refs.projectLevelForm.validate((valid) => {
        if (valid) {
          this.buttonloading = true
          let url = ""
          let params = {
            projectName: this.projectLevelForm.projectName
          }
          if(this.levelType == 'edit'){
            url = "/supervise/project/updateById"
            params.id = this.levelInfo.id
          } else {
            url = "/supervise/project/save"
            if(this.levelType === 'nextLevel'){
              params.parentId = this.levelInfo.id
            } else if(this.levelType === 'sameLevel'){
              params.parentId = this.levelInfo.parentId
            }
          }
          params.showLevel = '1'
          params.orgId = ''
          params.orgName = ''
          this.$api.handleProjectFun(url,params).then((res) => {
            if(res.data.code == 0){
              this.$message({ type: "success", message: this.levelType == 'edit' ? "编辑成功!" : "新增成功!", duration: 2000, onClose: () => {
                this.handleClose()
                this.tableLoading = true
                this.init()
              }, });
            } else {
              this.buttonloading = false
              this.$message.error(res.data.msg);
            }
          }).catch(() => {
            this.buttonloading = false
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    // 查询
    searchFun() {
      this.tableLoading = true;
      this.form.projectName = this.projectName
      this.init();
    },

    // 重置
    resetFun() {
      this.form = {
        projectName: ''
      };
      this.projectName = ''
      this.tableLoading = true
      this.init();
    },

    //展开菜单
    handleExpension(val){
      let expandRowItem = []
      let expandRow = []
      setTimeout(() => {
        switch(val){
          case '2':
            this.tableData.map((item) => {
              expandRow.push(item.id);
              expandRowItem.push(item)
            });
            break;
          case '3':
            this.tableData.map((item) => {
              expandRow.push(item.id);
              expandRowItem.push(item)
              if (item.supProList && item.supProList.length > 0) {
                item.supProList.map((itemChild) => {
                  expandRow.push(itemChild.id);
                  expandRowItem.push(itemChild)
                });
              }
            });
            break;
          case 'all': 
            this.tableData.map((item) => {
              expandRow.push(item.id);
              expandRowItem.push(item)
              if (item.supProList && item.supProList.length > 0) {
                item.supProList.map((itemChild) => {
                  expandRow.push(itemChild.id);
                  expandRowItem.push(itemChild)
                  if (itemChild.supProList && itemChild.supProList.length > 0) {
                    itemChild.supProList.map((itemThreeChild) => {
                      expandRow.push(itemThreeChild.id);
                      expandRowItem.push(itemThreeChild)
                      if (itemThreeChild.supProList && itemThreeChild.supProList.length > 0) {
                        itemThreeChild.supProList.map((itemFourChild) => {
                          expandRow.push(itemFourChild.id);
                          expandRowItem.push(itemFourChild)
                        });
                      }
                    });
                  }
                });
              }
            });
            break;
          default:
            break;
        }
        this.$nextTick(() => {
          this.expandRow = JSON.parse(JSON.stringify(expandRow))
          this.expandRowItem = JSON.parse(JSON.stringify(expandRowItem))
          console.log(expandRow)
          console.log(expandRowItem)
          this.resetTabDate(expandRowItem, val !== 'all' ? (Number(val) - 1)+"" : val)
        })
      },500)
    },

    //收起展开菜单
    resetTabDate(arr,type) {
      let data = arr || this.tableData;
      if(arr.length == 0){
        this.tableData.map((item) => {
          this.$refs.tableData.toggleRowExpansion(item,false);
          if ( Object.prototype.toString.call(item.supProList) == "[object Array]") {
            this.resetTabDate(item.supProList,type);
          }
        })
        return
      }
      data.forEach((item) => {
        if(type !== 'all'){
          if(Number(item.level) <= Number(type)){
            this.$refs.tableData.toggleRowExpansion(item,true);
          } else {
            this.$refs.tableData.toggleRowExpansion(item,false);
          }
        } else {
          this.$refs.tableData.toggleRowExpansion(item,true);
        }
        
        if ( Object.prototype.toString.call(item.supProList) == "[object Array]") {
          this.resetTabDate(item.supProList,type);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.contain {
  padding: 26px 30px 30px 30px;

  .form-header-btn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .header-title{
      color: #3D3E4F;
      font-size: 22px;
      font-weight: bold;
    }
    .sheet-btn {
      background: #003685;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
      width: auto;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }

  span.bold{
    color: #010334;
    font-weight: bold;
  }

  span.error{
    color: #C6000B;
  }

  /deep/.el-table {
    // margin-top: 14px;
  }

  /deep/.el-input__inner {
    // text-align: center;
  }

  /deep/.el-table th > .cell {
    padding-left: 14px;
    border-left: 2px solid #e4ebf5;
  }
  /deep/.el-table th:first-child > .cell {
    border-left: transparent;
  }
  /deep/.el-switch__label.is-active{
    color: #010334;
  }
  /deep/.el-switch__label{
    color: #010334;
  }
  .table-handle{
    margin-right: 10px;
    margin-left: 0;
  }
  .quick-add-project{
    width: 100%;
    height: 63px;
    border: 1px dashed #B5BDCF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #003685;
    font-weight: 400;
    margin-top: 5px;
    cursor: pointer;
    img{
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
  }
}
</style>
